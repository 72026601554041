import { authService } from './authservice'
import { toast } from 'react-toastify';

class HttpClient {
    private baseUrl = process.env.REACT_APP_API_BASE_URL;
    private activeRequestCount = 0;
    loadingBar: any;

    async get(url: string, requestInit: RequestInit = {}) {
        const response = await this.send(url, requestInit);
        return await response.json();
    }

    async post(url: string, data: any, requestInit: RequestInit = {}) {
        requestInit.method = 'POST';
        requestInit.body = JSON.stringify(data);

        return await this.send(url, requestInit);
    }

    async send(url: string, requestInit: RequestInit = {}) {
        const request = new Request(this.baseUrl + url, requestInit);
        const user = await authService.getUser();
        if (user) {
            request.headers.append('Authorization', 'Bearer: ' + user.access_token);
        }

        if (++this.activeRequestCount === 1) {
            this.loadingBar.continuousStart();
        }            

        try {
            const response = await fetch(request);

            if (response.status >= 300)
                throw new Error(response.status + ' ' + response.statusText);

            return response;
        }
        catch (error) {
            toast.error(error.message);
            throw error;
        }
        finally {
            if (--this.activeRequestCount === 0) {
                this.loadingBar.complete();
            }
        }
    }
}

export const http = new HttpClient();