import { UserManager, Log as OidcLog } from 'oidc-client'

class AuthService {
    userManager: UserManager;
    fullBaseUrl = process.env.REACT_APP_BASE_URL;

    constructor() {
        OidcLog.level = OidcLog.DEBUG;
        OidcLog.logger = console;

        const settings = {
            authority: process.env.REACT_APP_OIDC_AUTHORITY,
            client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
            redirect_uri: this.fullBaseUrl,
            silent_redirect_uri: this.fullBaseUrl + '/silent-authenticate.html',
            post_logout_redirect_uri: this.fullBaseUrl,
            scope: 'openid profile ' + process.env.REACT_APP_OIDC_API_SCOPE,
            response_type: 'id_token token',
            loadUserInfo: true,
            automaticSilentRenew: false
        };

        this.userManager = new UserManager(settings);
    }

    public async login() {
        let user;

        if (window.location.href.includes("#id_token")) {
            user = await this.userManager.signinRedirectCallback();
            window.history.replaceState({}, "", this.fullBaseUrl);
        }

        if (!user) {
            user = await this.userManager.getUser();
        }

        if (!user) {
            await this.userManager.signinRedirect();
        }
    }

    public async logout() {
        await this.userManager.signoutRedirect();
    }

    public async renewToken() {
        return await this.userManager.signinSilent();
    }

    public async getUser() {
        return await this.userManager.getUser();
    }
}

export const authService = new AuthService();