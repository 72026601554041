import React from 'react'
import * as Icon from 'react-feather';
import { http } from '../http'
import { withRouter, RouteComponentProps } from 'react-router-dom'

interface IProps extends RouteComponentProps { }

interface IState {
    schools?: ISchool[];
}

interface ISchoolLPID {
    lpid: string;
    institutionNumbers: string[];
}

interface ISchool {
    schoolId: string;
    databaseName: string;
    lpiDs: ISchoolLPID[];
}

class Schools extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
        };
        
        this.fetchSchools();
    }

    async fetchSchools() {
        const data = await http.get('/api/schools');
        const schools: ISchool[] = data.schools;

        this.setState({
            schools: schools
        });
    }

    async startConversion(school: ISchool) {
        this.props.history.push('/start-conversion/' + school.schoolId);
    }

    render() {
        return <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Schools</h1>
            </div>

            <table className="table table-striped table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Installation number</th>
                        <th scope="col">Database name</th>
                        <th scope="col">LPIDs / Institution numbers</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.schools?.map(school => <tr key={school.schoolId}>
                        <th scope="row">{school.schoolId}</th>
                        <td>{school.databaseName}</td>
                        <td>
                            {school.lpiDs.map(lpid => <div key={lpid.lpid}>
                                <strong>{lpid.lpid}</strong>: {lpid.institutionNumbers.join(', ')}
                            </div>)}
                        </td>
                        <td><button className="btn btn-secondary" onClick={() => this.startConversion(school)}><Icon.Play className="feather" /> Start conversion</button></td>
                    </tr>)}
                </tbody>
            </table>
        </>;
    }

    groupBy<T>(items: T[], selector: string | ((x: T) => any)) {
        return items.reduce((grouping: { key: any, values: T[] }[], item: T) => {
            var key = typeof selector === 'function' ? selector(item) : (item as any)[selector];

            let group = grouping.find(g => g.key === key);

            if (group === undefined) {
                group = { key: key, values: [] };
                grouping.push(group);
            }

            group.values.push(item);

            return grouping;
        }, []);
    };
}

export default withRouter(Schools);