import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { http } from '../http'

interface IProps extends RouteComponentProps { }
interface IState {}

class Admin extends React.Component<IProps,IState> {
    async refreshSchools() {
        const response = await http.post('/api/schools/refresh', {});
        const data = await response.json();

        this.props.history.push('/log/' + data.instanceId);
    }

    render() {
        return <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Administrative tasks</h1>
            </div>

            <button className="btn btn-danger" onClick={() => this.refreshSchools()}>Refresh schools</button>
        </>
    }
}

export default withRouter(Admin);