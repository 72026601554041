import React from 'react';
import * as Icon from 'react-feather';
import { User } from 'oidc-client';
import { authService } from './authservice';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { http } from './http';
import LoadingBar from 'react-top-loading-bar';
import Schools from './pages/schools';
import StartConversion from './pages/start-conversion';
import Conversions from './pages/conversions';
import ConversionDetails from './pages/conversion-details';
import Logs from './pages/logs';
import LogDetails from './pages/log-details';
import Admin from './pages/admin';
import TestEnvironment from './pages/testenvironment';

// Based on https://getbootstrap.com/docs/4.4/examples/dashboard/

interface IProps { }

interface IState {
  user?: User
}

export default class App extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
    };

    authService.getUser().then(user => {
      if (!user) return;

      this.setState({
        user: user
      });
    });
  }

  render() {
    return <>
      <Router>
        <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
          <Link to="/" className="navbar-brand col-sm-3 col-md-2 mr-0">iOmniwize to Scoodle Track</Link>
          {/* <input className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" /> */}
          <ul className="navbar-nav px-3">
            <li className="nav-item text-nowrap">
              <Link to="/" className="nav-link" onClick={() => authService.logout()}>{this.state.user?.profile.name} - Sign out</Link>
            </li>
          </ul>
        </nav>

        <div className="container-fluid">
          <div className="row">
            <nav className="col-md-2 d-none d-md-block bg-light sidebar">
              <div className="sidebar-sticky">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <NavLink exact to="/" className="nav-link" activeClassName="active">
                      <Icon.Home className="feather" />
                      Schools
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/conversions" className="nav-link" activeClassName="active">
                      <Icon.Layers className="feather" />
                      Conversions
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/testenvironment" className="nav-link" activeClassName="active">
                      <Icon.UserCheck className="feather" />
                      Test environment
                    </NavLink>
                  </li>
                </ul>

                <ul className="nav flex-column fixed-bottom" style={{ position: 'absolute' }}>
                  <li className="nav-item">
                    <NavLink exact to="/logs" className="nav-link" activeClassName="active">
                      <Icon.Book className="feather" />
                      Logs
                    </NavLink>
                    <NavLink exact to="/admin" className="nav-link" activeClassName="active">
                      <Icon.CloudLightning className="feather" />
                      Administrative tasks
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>

            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
              {/* <h1 className="h2">Schools</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group mr-2">
                <button type="button" className="btn btn-sm btn-outline-secondary">Share</button>
                <button type="button" className="btn btn-sm btn-outline-secondary">Export</button>
              </div>
              <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                <span data-feather="calendar"></span>
                This week
              </button>
            </div> */}

              <Switch>
                <Route exact path="/">
                  <Schools />
                </Route>
                <Route path="/start-conversion/:schoolId">
                  <StartConversion />
                </Route>
                <Route path="/conversions">
                  <Conversions />
                </Route>
                <Route path="/conversion/:conversionId">
                  <ConversionDetails />
                </Route>
                <Route path="/testenvironment">
                  <TestEnvironment />
                </Route>
                <Route path="/logs">
                  <Logs />
                </Route>
                <Route path="/log/:instanceId">
                  <LogDetails />
                </Route>
                <Route path="/admin">
                  <Admin />
                </Route>
              </Switch>
            </main>
          </div>
        </div>
      </Router>

      <LoadingBar onRef={(ref: any) => (http.loadingBar = ref)} />
      <ToastContainer />
    </>;
  }
}
