import React from 'react'
import * as Icon from 'react-feather';
import { http } from '../http'
import { withRouter, RouteComponentProps } from 'react-router-dom'

interface IRouteProps {
    schoolId: string;
}

interface ISchoolLPID {
    lpid: string;
    institutionNumbers: string[];
    includeInMigration: boolean;
}

interface ISchool {
    schoolId: string;
    databaseName: string;
    lpiDs: ISchoolLPID[];
}

interface IProps extends RouteComponentProps<IRouteProps> { }

interface IState {
    school?: ISchool;
	isEnabledCareFileTestModule: boolean;
	isEnabledCareFileProductionModule: boolean;
	isEnabledResultsTestModule: boolean;
	isEnabledResultsProductionModule: boolean;
	
    conversions?: IConversion[];
    
    isProduction: boolean;

    includeModuleScoodleBaO: boolean;
    includeModuleLVS: boolean;
    includeModuleKVS: boolean;
    includeModuleClassScreenings: boolean;
    includeModuleCareFile: boolean;
    includeModuleResults: boolean;
    schoolYearForResults: string;

    scheduleDate: Date;
}

interface IConversion {
    id: string;
    startedTimestamp: string,
    schoolDBName: string,
    lpiDs: string[],
    includedModules: string[],
    isProduction: boolean;
    isSuccessfullyCompleted: boolean,
    completedTimestamp?: string
}

class StartConversion extends React.Component<IProps, IState> {
    private timer?: NodeJS.Timeout;

    constructor(props: IProps) {
        super(props);

        this.state = {
            isProduction: false,

			isEnabledCareFileTestModule: true,
			isEnabledCareFileProductionModule: true,
			isEnabledResultsTestModule: true,
			isEnabledResultsProductionModule: true,

            includeModuleScoodleBaO: true,
            includeModuleLVS: true,
            includeModuleKVS: true,
            includeModuleClassScreenings: true,
            includeModuleCareFile: true,
            includeModuleResults: false,
            schoolYearForResults: "2020-21",

            scheduleDate: new Date()
        };

        this.fetchSchool();
        this.fetchConversions();
    }

    componentDidMount() {
        this.timer = setInterval(() => this.fetchConversions(), 3000);
    }

    componentWillUnmount() {
        if (this.timer)
            clearInterval(this.timer);
    }

    async fetchSchool() {
        const schoolId = this.props.match.params.schoolId;

        const data = await http.get('/api/school/' + encodeURIComponent(schoolId));
        const school: ISchool = data.school;

        school.lpiDs.forEach(i => i.includeInMigration = true);

        this.setState({
            school: school,
			isEnabledCareFileTestModule: data.isEnabledCareFileTestModule,
			isEnabledCareFileProductionModule: data.isEnabledCareFileProductionModule,
			isEnabledResultsTestModule: data.isEnabledResultsTestModule,
			isEnabledResultsProductionModule: data.isEnabledResultsProductionModule,
			includeModuleCareFile: this.state.includeModuleCareFile && ((data.isEnabledCareFileProductionModule && this.state.isProduction) || data.isEnabledCareFileTestModule),
			includeModuleResults: this.state.includeModuleResults && ((data.isEnabledResultsProductionModule && this.state.isProduction) || data.isEnabledResultsTestModule)
        });
    }

    toggleLPIDForMigration(lpid: ISchoolLPID): void {
        lpid.includeInMigration = !lpid.includeInMigration;
        this.setState({});
    }

    async startConversion() {
        //alert(this.state.scheduleDate.toISOString());

        const school = this.state.school;
        if (!school) return;

        const response = await http.post('/api/conversion/start', {
            schoolId: school.schoolId,
            isProduction: this.state.isProduction,
            lpids: school.lpiDs.filter(i => i.includeInMigration).map(i => i.lpid),
            includeModuleScoodleBaO: this.state.includeModuleScoodleBaO,
            includeModuleLVS: this.state.includeModuleLVS,
            includeModuleKVS: this.state.includeModuleKVS,
            includeModuleClassScreenings: this.state.includeModuleClassScreenings,
            includeModuleCareFile: this.state.includeModuleCareFile,
            includeModuleResults: this.state.includeModuleResults,
            schoolYearForResults: this.state.schoolYearForResults
        });

        const data = await response.json();
		if (data.success) {
			this.props.history.push('/conversion/' + encodeURIComponent(data.conversionId));
		}
		else {
			alert(data.message);
		}
    }

    async fetchConversions() {
        const schoolId = this.props.match.params.schoolId;

        const data = await http.get('/api/conversions/school/' + encodeURIComponent(schoolId));
        const conversions: IConversion[] = data.conversions;

        this.setState({
            conversions: conversions
        });
    }

    async openConversion(conversion: IConversion) {
        this.props.history.push('/conversion/' + conversion.id);
    }

    render() {
        const school = this.state.school;

        if (!school) return <div></div>;

        return <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Start conversion</h1>
            </div>

            <p><strong>Installation number:</strong> {school.schoolId}</p>
            <p><strong>Database name:</strong> {school.databaseName}</p>

            <p><strong>LPIDs / Institution numbers:</strong></p>
            {school.lpiDs.length > 0 && <table className="table table-bordered">
                <thead>
                    <tr>
                        {school.lpiDs.map(lpid => <th scope="col" key={lpid.lpid}>
                            <input type="checkbox" defaultChecked={lpid.includeInMigration} onClick={() => this.toggleLPIDForMigration(lpid)} /> {lpid.lpid}
                        </th>)}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {school.lpiDs.map(lpid => <td key={lpid.lpid}>
                            {lpid.institutionNumbers.map(institutionNumber => <div key={institutionNumber}>
                                <label>
                                    {institutionNumber}
                                </label>
                            </div>)}
                        </td>)}
                    </tr>
                </tbody>
            </table>}

            <p><strong>Modules:</strong></p>
            <p>
                <label>
                    <input type="checkbox" defaultChecked={this.state.includeModuleScoodleBaO} onClick={() => this.setState({ includeModuleScoodleBaO: !this.state.includeModuleScoodleBaO })} /> Scoodle BaO
                </label>
                <br />
                <label>
                    <input type="checkbox" defaultChecked={this.state.includeModuleLVS} onClick={() => this.setState({ includeModuleLVS: !this.state.includeModuleLVS })} /> LVS
                </label>
                <br />
                <label>
                    <input type="checkbox" defaultChecked={this.state.includeModuleKVS} onClick={() => this.setState({ includeModuleKVS: !this.state.includeModuleKVS })} /> KVS
                </label>
                <br />
                <label>
                    <input type="checkbox" defaultChecked={this.state.includeModuleClassScreenings} onClick={() => this.setState({ includeModuleClassScreenings: !this.state.includeModuleClassScreenings })} /> Class screenings
                </label>
                <br />
                <label>
                    <input type="checkbox" checked={this.state.includeModuleCareFile} onClick={() => this.setState({ includeModuleCareFile: !this.state.includeModuleCareFile })} disabled={!((this.state.isEnabledCareFileProductionModule && this.state.isProduction) || this.state.isEnabledCareFileTestModule)} /> Care file
                </label>
				{!((this.state.isEnabledCareFileProductionModule && this.state.isProduction) || this.state.isEnabledCareFileTestModule) && <label style={{marginLeft: "20px", color: "red"}}>This version of application can't work with Care file database.</label>}
				<br />
                <label>
                    <input type="checkbox" checked={this.state.includeModuleResults} onClick={() => this.setState({ includeModuleResults: !this.state.includeModuleResults })} disabled={!((this.state.isEnabledResultsProductionModule && this.state.isProduction) || this.state.isEnabledResultsTestModule)}/> Results / Courses
                </label>
				{((this.state.isEnabledResultsProductionModule && this.state.isProduction) || this.state.isEnabledResultsTestModule) ?
                <label style={{marginLeft: "20px"}}>
					Schoolyear:
					<select style={{marginLeft: "5px"}} defaultValue={this.state.schoolYearForResults} onChange={(e) => this.setState({schoolYearForResults: e.target.value})}>
						<option value="2020-21">2020-21</option>
						<option value="2021-22">2021-22</option>
					</select>
                </label> :
				<label style={{marginLeft: "20px", color: "red"}}>This version of application can't work with Results / Courses database.</label>
				}
            </p>
            <p><strong>Target environment:</strong></p>
            <label>
                <input type="radio" name="target-environment" defaultChecked={!this.state.isProduction} onClick={() => this.setState({ isProduction: false, includeModuleCareFile: this.state.isEnabledCareFileTestModule && this.state.includeModuleCareFile, includeModuleResults: this.state.isEnabledResultsTestModule && this.state.includeModuleResults })} /> Test
            </label>
            <br />
            <label>
                <input type="radio" name="target-environment" defaultChecked={this.state.isProduction} onClick={() => this.setState({ isProduction: true, includeModuleCareFile: this.state.isEnabledCareFileProductionModule && this.state.includeModuleCareFile, includeModuleResults: this.state.isEnabledResultsProductionModule && this.state.includeModuleResults})} /> Production
            </label>
            
            {/* <p><strong>Schedule:</strong></p>
            <p>
                <input type="date" defaultValue={this.toISOLocal(this.state.scheduleDate).split('T')[0]} onChange={e => this.setState({ scheduleDate: new Date(e.target.value + ' ' + this.state.scheduleDate.toTimeString()) })} />
                <input type="time" step="900" defaultValue={this.toISOLocal(this.state.scheduleDate).split('T')[1]} onChange={e => this.setState({ scheduleDate: new Date(this.state.scheduleDate.toDateString() + ' ' + e.target.value) })} />
            </p> */}

            <p className="pt-3">
                <button className="btn btn-secondary" onClick={() => this.startConversion()}><Icon.Play className="feather" /> Start conversion</button>
            </p>

            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-2 mb-3 border-bottom">
                <h4 className="h4">Conversion history</h4>
            </div>

            <table className="table table-striped table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Target env.</th>
                        <th scope="col">LPIDs</th>
                        <th scope="col">Modules</th>
                        <th scope="col">Started</th>
                        <th scope="col">Is completed</th>
                        <th scope="col">Completed</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.conversions?.map(conversion => <tr key={conversion.id}>
                        <th scope="row">{conversion.id}</th>
                        <td>{conversion.isProduction ? 'Production' : 'Test'}</td>
                        <td>{conversion.lpiDs.join(', ')}</td>
                        <td>{conversion.includedModules.join(', ')}</td>
                        <td>{this.dateToString(conversion.startedTimestamp)}</td>
                        <td>{conversion.isSuccessfullyCompleted && <Icon.Check className="feather" />}</td>
                        <td>{this.dateToString(conversion.completedTimestamp)}</td>
                        <td><button className="btn btn-secondary" onClick={() => this.openConversion(conversion)}><Icon.Play className="feather" /> View details</button></td>
                    </tr>)}
                </tbody>
            </table>
        </>
    }

    groupBy<T>(items: T[], selector: string | ((x: T) => any)) {
        return items.reduce((grouping: { key: any, values: T[] }[], item: T) => {
            var key = typeof selector === 'function' ? selector(item) : (item as any)[selector];

            let group = grouping.find(g => g.key === key);

            if (group === undefined) {
                group = { key: key, values: [] };
                grouping.push(group);
            }

            group.values.push(item);

            return grouping;
        }, []);
    };

    toISOLocal(d : Date) {
        var z  = (n : number) =>  ('0' + n).slice(-2);
      
        return d.getFullYear() + '-'
               + z(d.getMonth()+1) + '-' +
               z(d.getDate()) + 'T' +
               z(d.getHours()) + ':'  + 
               z(Math.ceil(d.getMinutes() / 15) * 15)
    }
    
    dateToString(date? : string) {
        if (!date) return "";
        return new Date(date).toLocaleString();
    }
}

export default withRouter(StartConversion);