import React from 'react'
import * as Icon from 'react-feather';
import { http } from '../http'
import { withRouter, RouteComponentProps } from 'react-router-dom'

interface IProps extends RouteComponentProps { }

interface IState {
    conversions?: IConversion[];
}

interface IConversion {
    id: string;
    startedTimestamp: string,
    schoolDBName: string,
    lpiDs: string[],
    includedModules: string[],
    isProduction: boolean;
    usersBlocked: boolean,
    isSuccessfullyCompleted: boolean,
    completedTimestamp?: string
}

class Conversions extends React.Component<IProps, IState> {
    private timer?: NodeJS.Timeout;

    constructor(props: IProps) {
        super(props);

        this.state = {
        };

        this.fetchConversions();
    }

    componentDidMount() {
        this.timer = setInterval(() => this.fetchConversions(), 3000);
    }

    componentWillUnmount() {
        if (this.timer)
            clearInterval(this.timer);
    }

    async fetchConversions() {
        const data = await http.get('/api/conversions');
        const conversions: IConversion[] = data.conversions;

        this.setState({
            conversions: conversions
        });
    }

    async openConversion(conversion: IConversion) {
        this.props.history.push('/conversion/' + conversion.id);
    }

    render() {
        const getClassName = (conversion : IConversion) => {
            if (conversion.isSuccessfullyCompleted) {
                if (conversion.usersBlocked) {
                    return "table-warning";
                }
                else {
                    return "table-success";
                }
            }
        }

        return <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Conversions</h1>
            </div>

            <table className="table table-striped table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Database name</th>
                        <th scope="col">Target env.</th>
                        <th scope="col">LPIDs</th>
                        <th scope="col">Modules</th>
                        <th scope="col">Started</th>
                        <th scope="col">Users blocked</th>
                        <th scope="col">Is completed</th>
                        <th scope="col">Completed</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.conversions?.map(conversion => <tr key={conversion.id} className={getClassName(conversion)}>
                        <th scope="row">{conversion.id}</th>
                        <td>{conversion.schoolDBName}</td>
                        <td>{conversion.isProduction ? 'Production' : 'Test'}</td>
                        <td>{conversion.lpiDs.join(', ')}</td>
                        <td>{conversion.includedModules.join(', ')}</td>
                        <td>{this.dateToString(conversion.startedTimestamp)}</td>
                        <td>{conversion.usersBlocked && <Icon.Check className="feather" />}</td>
                        <td>{conversion.isSuccessfullyCompleted && <Icon.Check className="feather" />}</td>
                        <td>{this.dateToString(conversion.completedTimestamp)}</td>
                        <td><button className="btn btn-secondary" onClick={() => this.openConversion(conversion)}><Icon.Play className="feather" /> View details</button></td>
                    </tr>)}
                </tbody>
            </table>
        </>;
    }

    dateToString(date? : string) {
        if (!date) return "";
        return new Date(date).toLocaleString();
    }
}

export default withRouter(Conversions);