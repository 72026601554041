import React from 'react'
import { http } from '../http'
import { withRouter, RouteComponentProps } from 'react-router-dom'

interface IProps extends RouteComponentProps { }

interface IState {
    schools?: ISchool[];
}

interface ISchoolLPID {
    lpid: string;
    institutionNumbers: string[];
}

interface ISchool {
    schoolId: string;
    databaseName: string;
    includedModules: string[];
    lpiDs: ISchoolLPID[];
    startedTimestamp: string;
}

class TestEnvironment extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
        };

        this.fetchSchools();
    }

    async fetchSchools() {
        const data = await http.get('/api/testenvironment/schools');
        const schools: ISchool[] = data.schools;

        this.setState({
            schools: schools
        });
    }

    async resetTestEnvironment() {
        const confirm: boolean = window.confirm("Are sure you want to reset the test environment and remove all the converted schools?");

        if (confirm) {
            await http.post('/api/testenvironment/reset', {});
        }

        this.fetchSchools();
    }

    render() {
        return <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Schools on test environment</h1>
            </div>

            <div className="pt-2 pb-4 text-right">
                {<button onClick={() => this.resetTestEnvironment()} className="btn btn-danger ml-3">Reset test environment</button>}
            </div>

            <table className="table table-striped table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Installation number</th>
                        <th scope="col">Database name</th>
                        <th scope="col">LPIDs / Institution numbers</th>
                        <th scope="col">Modules</th>
                        <th scope="col">Created</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.schools?.map(school => <tr key={school.schoolId}>
                        <th scope="row">{school.schoolId}</th>
                        <td>{school.databaseName}</td>
                        <td>
                            {school.lpiDs.map(lpid => <div key={lpid.lpid}>
                                <strong>{lpid.lpid}</strong>: {lpid.institutionNumbers.join(', ')}
                            </div>)}
                        </td>
                        <td>{school.includedModules.join(', ')}</td>
                        <td>{this.dateToString(school.startedTimestamp)}</td>
                    </tr>)}
                </tbody>
            </table>
        </>;
    }

    groupBy<T>(items: T[], selector: string | ((x: T) => any)) {
        return items.reduce((grouping: { key: any, values: T[] }[], item: T) => {
            var key = typeof selector === 'function' ? selector(item) : (item as any)[selector];

            let group = grouping.find(g => g.key === key);

            if (group === undefined) {
                group = { key: key, values: [] };
                grouping.push(group);
            }

            group.values.push(item);

            return grouping;
        }, []);
    };
    
    dateToString(date? : string) {
        if (!date) return "";
        return new Date(date).toLocaleString();
    }
}

export default withRouter(TestEnvironment);