import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import * as Icon from 'react-feather';
import { http } from '../http'

interface IProps extends RouteComponentProps { }
interface IState {
    instances?: IInstance[];
}

interface IInstance {
    instanceId: string;
}

class Logs extends React.Component<IProps, IState> {
    private timer?: NodeJS.Timeout;

    constructor(props: IProps) {
        super(props);

        this.state = {
        };

        this.fetchInstances();
    }

    componentDidMount() {
        this.timer = setInterval(() => this.fetchInstances(), 3000);
    }

    componentWillUnmount() {
        if (this.timer)
            clearInterval(this.timer);
    }

    async fetchInstances() {
        const data = await http.get('/api/logs/instances');
        const instances: IInstance[] = data.instances;

        this.setState({
            instances: instances
        });
    }

    async openInstance(instance: IInstance) {
        this.props.history.push('/log/' + instance.instanceId);
    }

    render() {
        return <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Orchestration instances</h1>
            </div>

            <table className="table table-striped table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Created</th>
                        <th scope="col">LastUpdated</th>
                        <th scope="col">Status</th>
                        <th scope="col">Custom status</th>
                        <th scope="col">History</th>
                        <th scope="col">Input</th>
                        <th scope="col">Output</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.instances?.map((instance: any) => <tr key={instance.instanceId}>
                        <td><button className="btn btn-secondary" onClick={() => this.openInstance(instance)}><Icon.Play className="feather" /> View details</button></td>
                        <th scope="row">{instance.instanceId}</th>
                        <td>{instance.name}</td>
                        <td>{this.dateToString(instance.createdTime)}</td>
                        <td>{this.dateToString(instance.lastUpdatedTime)}</td>
                        <td>{this.getOrchestrationStatus(instance.runtimeStatus)}</td>
                        <td>{instance.customStatus}</td>
                        <td>{instance.history}</td>
                        <td style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', minWidth: '100px' }}>{JSON.stringify(instance.input, undefined, 4)}</td>
                        <td style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', minWidth: '100px' }}>{instance.output}</td>
                        {/* <td>{instance.isSuccessfullyCompleted && <Icon.Check className="feather" />}</td> */}
                    </tr>)}
                </tbody>
            </table>
        </>;
    }

    getOrchestrationStatus(status : any) {
        if (status === 0) return "Running";
        if (status === 1) return "Completed";
        if (status === 2) return "ContinuedAsNew";
        if (status === 3) return "Failed";
        if (status === 4) return "Canceled";
        if (status === 5) return "Terminated";
        if (status === 6) return "Pending";

        return undefined;
    }
    
    dateToString(date? : string) {
        if (!date) return "";
        return new Date(date).toLocaleString();
    }
}

export default withRouter(Logs);