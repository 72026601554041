import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import * as Icon from 'react-feather';
import { http } from '../http'

interface IRouteProps {
    instanceId: string;
}

interface IProps extends RouteComponentProps<IRouteProps> { }
interface IState {
    entries?: IEntry[];
}

interface IEntry {
    InstanceId?: string;
}

class LogDetails extends React.Component<IProps, IState> {
    private timer?: NodeJS.Timeout;

    constructor(props: IProps) {
        super(props);

        this.state = {
        };

        this.fetchEntries();
    }

    componentDidMount() {
        this.timer = setInterval(() => this.fetchEntries(), 3000);
    }

    componentWillUnmount() {
        if (this.timer)
            clearInterval(this.timer);
    }

    async fetchEntries() {
        const data = await http.get('/api/logs/instance/' + this.props.match.params.instanceId);
        const entries: IEntry[] = data.entries;

        this.setState({
            entries: entries
        });
    }

    async openInstance(entry: IEntry) {
        this.props.history.push('/log/' + entry.InstanceId);
    }

    async rewindOrchestration() {
        await http.post('/api/logs/rewind', {
            instanceId: this.props.match.params.instanceId
        });
    }

    render() {
        const propsToUnset = { EventId: undefined, EventType: undefined, Timestamp: undefined, IsPlayed: undefined };

        return <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Log for instance {this.props.match.params.instanceId}</h1>
            </div>

            
            <div className="pt-2 pb-4 text-right">
                <button onClick={() => this.rewindOrchestration()} className="btn btn-secondary">Rewind orchestration</button>
            </div>

            <table className="table table-striped table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Id</th>
                        <th scope="col">Type</th>
                        <th scope="col">Timestamp</th>
                        <th scope="col">IsPlayed</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.entries?.map((entry: any, index : number) => <tr key={index}>
                        <td>{entry.InstanceId && <button className="btn btn-secondary" onClick={() => this.openInstance(entry)}><Icon.Play className="feather" /> View details</button>}</td>
                        <th scope="row">{entry.EventId !== -1 && entry.EventId}</th>
                        <td>{this.getEventType(entry.EventType)}</td>
                        <td>{this.dateToString(entry.Timestamp)}</td>
                        <td>{entry.IsPlayed && <Icon.Check className="feather" />}</td>
                        <td style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', minWidth: '100px' }}>{JSON.stringify({ ...entry, ...propsToUnset }, undefined, 4)}</td>
                        {/* <td>{instance.isSuccessfullyCompleted && <Icon.Check className="feather" />}</td> */}
                    </tr>)}
                </tbody>
            </table>
        </>;
    }

    getEventType(type: any) {
        if (type === 0) return "ExecutionStarted";
        if (type === 1) return "ExecutionCompleted";
        if (type === 2) return "ExecutionFailed";
        if (type === 3) return "ExecutionTerminated";
        if (type === 4) return "TaskScheduled";
        if (type === 5) return "TaskCompleted";
        if (type === 6) return "TaskFailed";
        if (type === 7) return "SubOrchestrationInstanceCreated";
        if (type === 8) return "SubOrchestrationInstanceCompleted";
        if (type === 9) return "SubOrchestrationInstanceFailed";
        if (type === 10) return "TimerCreated";
        if (type === 11) return "TimerFired";
        if (type === 12) return "OrchestratorStarted";
        if (type === 13) return "OrchestratorCompleted";
        if (type === 14) return "EventSent";
        if (type === 15) return "EventRaised";
        if (type === 16) return "ContinueAsNew";
        if (type === 17) return "GenericEvent";
        if (type === 18) return "HistoryState";

        return undefined;
    }
    
    dateToString(date? : string) {
        if (!date) return "";
        return new Date(date).toLocaleString();
    }
}

export default withRouter(LogDetails);