import React from 'react'
import * as Icon from 'react-feather';
import { http } from '../http'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { toast } from 'react-toastify';

interface IRouteProps {
    conversionId: string;
}

interface IProps extends RouteComponentProps<IRouteProps> { }

interface IState {
    conversion?: IConversion;
    careLogs?: ILog[];
}

interface IConversion {
    id: string,
    schoolId: string,
    schoolDBName: string,
    lpiDs: string[];
    institutionNumbers: string[];
    isProduction: boolean;
    includedModules: string[];
    startedTimestamp: string;
    isSuccessfullyCompleted: boolean;
    completedTimestamp: string;
    isFailed: boolean,
    isRunning: boolean,
    usersBlocked: boolean;
    tasks: ITask[];
}

interface ITask {
    id: string;
    name: string;
    startedTimestamp?: string,
    isSuccessfullyCompleted: boolean,
    completedTimestamp?: string,
    isCompletedWithError: boolean,
    error?: string;
}

interface ILog {
    type: string;
    level: string;
    description: string;
    sourceValue: string;
    targetValue: string;
    createdOn: string;
}

class ConversionDetails extends React.Component<IProps, IState> {
    private timer?: NodeJS.Timeout;

    constructor(props: IProps) {
        super(props);

        this.state = {
        };

        this.fetchConversion();
    }

    componentDidMount() {
        this.timer = setInterval(() => this.fetchConversion(), 3000);
    }

    componentWillUnmount() {
        if (this.timer)
            clearInterval(this.timer);
    }

    async fetchConversion() {
        const conversionId = this.props.match.params.conversionId;

        const data = await http.get('/api/conversion/' + encodeURIComponent(conversionId));
        const conversion: IConversion = data.conversion;

        this.setState({
            conversion: conversion
        });
    }

    async showLog() {
        const conversionId = this.props.match.params.conversionId;

        const data = await http.get('/api/conversion/log/' + encodeURIComponent(conversionId));

        this.setState({
            careLogs: data.careLogs
        });
    }

    async restartConversion() {
        if (!this.state.conversion) return;

        await http.post('/api/conversion/restart', {
            conversionId: this.state.conversion.id
        });

        this.fetchConversion();
    }

    async removeLocksForConversion() {
        if (!this.state.conversion) return;

        await http.post('/api/conversion/removelocks', {
            conversionId: this.state.conversion.id
        });

        toast.success("Locks for conversion removed");

        this.fetchConversion();
    }

    async unblockUsers() {
        if (!this.state.conversion) return;

        await http.post('/api/conversion/unblockusers', {
            conversionId: this.state.conversion.id
        });

        this.fetchConversion();
    }

    async rollbackConversion(): Promise<void> {
        if(!this.state.conversion) return;

        const confirm: boolean = window.confirm("Are sure you want to rollback this conversion?");

        if(confirm) {
            toast.info("Rollback process started");

            try {
                await http.post(
                    '/api/conversion/rollback', 
                    { conversionId: this.state.conversion.id }    
                );

                toast.success("Rollback process finished");
            }
            catch (error) {
                toast.error("Rollback process failed: " + error);
            }
        }
        
        this.fetchConversion();
    }
    
    render() {
        const conversion = this.state.conversion;
        if (!conversion) return <div></div>;

        return <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Conversion for {conversion.schoolDBName}</h1>
            </div>

            <p><strong>Installation number:</strong> {conversion.schoolId}</p>
            <p><strong>Database name:</strong> {conversion.schoolDBName}</p>
            <p><strong>LPIDs:</strong> {conversion.lpiDs.join(', ')}</p>
            <p><strong>Institution numbers:</strong> {conversion.institutionNumbers.join(', ')}</p>
            <p><strong>Modules:</strong> {conversion.includedModules.join(', ')}</p>
            <p><strong>Target environment:</strong> {conversion.isProduction ? 'Production' : 'Test'}</p>
            <p><strong>Started:</strong> {this.dateToString(conversion.startedTimestamp)}</p>
            <p><strong>Status:</strong> {conversion.isSuccessfullyCompleted && 'Completed'}{conversion.isFailed && 'Failed'}{conversion.isRunning && 'Running'}</p>
            <p><strong>Users blocked:</strong> {conversion.usersBlocked ? 'yes' : 'no'}</p>
            <p><strong>Completed:</strong> {this.dateToString(conversion.completedTimestamp)}</p>

            <div className="pt-2 pb-4 text-right">
                {conversion.isFailed && <button onClick={() => this.removeLocksForConversion()} className="btn btn-secondary mr-3">Remove locks</button>}
                {conversion.isFailed && <button onClick={() => this.restartConversion()} className="btn btn-secondary">Restart conversion</button>}
                {conversion.isSuccessfullyCompleted && conversion.usersBlocked && <button onClick={() => this.unblockUsers()} className="btn btn-secondary">Unblock users</button>}
                {!conversion.isRunning && <button onClick={() => this.rollbackConversion()} className="btn btn-danger ml-3">Rollback conversion</button>}
            </div>

            <div className="mb-4 border p-1">
                <div className="pt-2 pb-2 text-right">
                    <button onClick={() => this.showLog()} className="btn btn-secondary">Show log of care db</button>
                </div>
                {
                this.state.careLogs && 
                    <table className="table table-striped table-hover">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Type</th>
                                <th scope="col">Level</th>
                                <th scope="col">Description</th>
                                <th scope="col">SourceValue</th>
                                <th scope="col">TargetValue</th>
                                <th scope="col">CreatedOn</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.careLogs.map((logItem, index) => <tr key={index}>
                                <td>{logItem.type}</td>
                                <td>{logItem.level}</td>
                                <td>{logItem.description}</td>
                                <td>{logItem.sourceValue}</td>
                                <td>{logItem.targetValue}</td>
                                <td>{this.dateToString(logItem.createdOn)}</td>
                            </tr>)}
                        </tbody>
                    </table>
                }
            </div>

            <table className="table table-striped table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Started</th>
                        <th scope="col">Is completed</th>
                        <th scope="col">Completed</th>
                        <th scope="col">Error</th>
                    </tr>
                </thead>
                <tbody>
                    {conversion.tasks.map(task => <tr key={task.id}>
                        <th scope="row">{task.id}</th>
                        <td>{task.name}</td>
                        <td>{this.dateToString(task.startedTimestamp)}</td>
                        <td>{task.isSuccessfullyCompleted ? <Icon.Check className="feather" /> : (task.isCompletedWithError ? <Icon.Slash className="feather" /> : <></>)}</td>
                        <td>{this.dateToString(task.completedTimestamp)}</td>
                        <td>{task.error}</td>
                    </tr>)}
                </tbody>
            </table>
        </>;
    }
    
    dateToString(date? : string) {
        if (!date) return "";
        return new Date(date).toLocaleString();
    }
}

export default withRouter(ConversionDetails);